import {
  CaretSortIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Cross2Icon,
  EyeOpenIcon,
  ImageIcon,
} from "@radix-ui/react-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "src/components/ui/drawer";
import { Input } from "src/components/ui/input";
import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area";
import { Skeleton } from "src/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { AEBImage, useProductsAEB } from "../../helper/ProductContextAEB";
import { PlusIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { getUserRoleFromToken } from "../../../utils/auth";

// 1) ReactSortable importieren
import { ReactSortable } from "react-sortablejs";

const ProductTableAEB: React.FC = () => {
  const {
    productList = [],
    fetchProducts,
    searchProducts,
    updateProductStatus,
    fetchProductGroups,
    updatePriceStatus,
    productGroups,
    totalPages,
    totalProducts,
    currentPage,
    setCurrentPage,
    editProduct,
    brands,
    fetchBrands,
    product,
    fetchProductById,
    deleteImage,
    uploadImages,
    updateImageOrder,
  } = useProductsAEB();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedArticleId, setSelectedArticleId] = useState<number | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>(undefined);
  const userRole = getUserRoleFromToken();
  const [filters, setFilters] = useState({
    active: false,
    inactive: false,
    showPrice: false,
    requestPrice: false,
  });
  const [sortBy, setSortBy] = useState<string>("articleId");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [selectedSort, setSelectedSort] = useState<string | undefined>(undefined);

  // Dateiupload via react-dropzone
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: true,
  });
  const removeFile = (fileName: string) => {
    setSelectedFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  // Upload-Handler
  const handleUpload = async () => {
    if (!selectedFiles.length || !product) return;
    try {
      await uploadImages(selectedFiles, product.articleId, userRole);
      setSelectedFiles([]);
    } catch (error) {
      console.error("Upload fehlgeschlagen:", error);
    }
  };

  // Scroll-Funktionen
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Drawer: Produkt laden
  useEffect(() => {
    if (selectedArticleId !== null) {
      fetchProductById(selectedArticleId);
    }
  }, [selectedArticleId]);

  // Produkte filtern/fetchen
  const memoizedFetchProducts = useCallback(() => {
    const statusFilters: string[] = [];
    if (filters.active) statusFilters.push("active=true");
    if (filters.inactive) statusFilters.push("active=false");
    if (filters.showPrice) statusFilters.push("showPrice=true");
    if (filters.requestPrice) statusFilters.push("showPrice=false");
    const statusFilterString = statusFilters.join("&");

    fetchProducts(
      currentPage,
      sortBy,
      sortDirection,
      selectedGroup,
      selectedBrand,
      statusFilterString
    );
  }, [
    currentPage,
    sortBy,
    sortDirection,
    selectedGroup,
    selectedBrand,
    filters,
    fetchProducts,
  ]);

  // Produkte suchen
  const memoizedSearchProducts = useCallback(
    (term: string) => {
      searchProducts(term, 1);
    },
    [searchProducts]
  );

  // Start-Fetch
  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        memoizedSearchProducts(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      memoizedFetchProducts();
      fetchProductGroups();
      fetchBrands();
    }
  }, [
    searchTerm,
    selectedGroup,
    selectedBrand,
    selectedSort,
    currentPage,
    filters,
  ]);

  // Filter-Handling
  const handleGroupChange = useCallback(
    (value: string) => {
      if (value !== selectedGroup) {
        setCurrentPage(1);
        setSelectedGroup(value);
      }
    },
    [selectedGroup, setCurrentPage]
  );

  const handleBrandChange = useCallback(
    (value: string) => {
      if (value !== selectedBrand) {
        setCurrentPage(1);
        setSelectedBrand(value);
      }
    },
    [selectedBrand, setCurrentPage]
  );

  const handleFilterChange = useCallback(
    (filter: "active" | "inactive" | "showPrice" | "requestPrice", checked: boolean) => {
      const newFilters = { ...filters };
      newFilters[filter] = checked;
      switch (filter) {
        case "active":
          if (checked) newFilters.inactive = false;
          break;
        case "inactive":
          if (checked) newFilters.active = false;
          break;
        case "showPrice":
          if (checked) newFilters.requestPrice = false;
          break;
        case "requestPrice":
          if (checked) newFilters.showPrice = false;
          break;
        default:
          break;
      }
      setCurrentPage(1);
      setFilters(newFilters);
    },
    [filters, setCurrentPage]
  );

  // Sortierung
  const handleSortChange = useCallback(
    (field: string) => {
      const isAsc = sortBy === field && sortDirection === "ASC";
      setSortBy(field);
      setSortDirection(isAsc ? "DESC" : "ASC");
      setSelectedSort(`${field}-${isAsc ? "DESC" : "ASC"}`);
    },
    [sortBy, sortDirection]
  );

  // Pagination
  const handlePageSelectInc = useCallback(() => {
    const newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      scrollToTop();
    }
  }, [currentPage, totalPages, setCurrentPage, scrollToTop]);

  const handlePageSelectDec = useCallback(() => {
    const newPage = currentPage > 1 ? currentPage - 1 : 1;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      scrollToTop();
    }
  }, [currentPage, setCurrentPage, scrollToTop]);

  const handlePageSelectFirst = useCallback(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      scrollToTop();
    }
  }, [currentPage, setCurrentPage, scrollToTop]);

  const handlePageSelectLast = useCallback(() => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
      scrollToTop();
    }
  }, [totalPages, currentPage, setCurrentPage, scrollToTop]);

  const handleSearch = useCallback(() => {
    memoizedSearchProducts(searchTerm);
  }, [searchTerm, memoizedSearchProducts]);

  const handleResetFilters = useCallback(() => {
    setSelectedGroup(undefined);
    setSelectedBrand(undefined);
    setSelectedSort(undefined);
    setSortDirection("ASC");
    setSortBy("articleId");
    setSearchTerm("");
    setFilters({
      active: false,
      inactive: false,
      showPrice: false,
      requestPrice: false,
    });
    memoizedFetchProducts();
  }, []);

  const renderPageNumbers = useMemo(() => {
    return (
      <div className="flex items-center justify-center">{`Seite ${currentPage} von ${totalPages}`}</div>
    );
  }, [currentPage, totalPages]);

  // Kleines Bildchen in der Tabelle (links)
  const ProductImageCell: React.FC<{
    imageUrl: string;
    articleId: number;
  }> = ({ imageUrl, articleId }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
      <TableCell className="font-medium w-20">
        {imageUrl ? (
          <div className="relative w-16 h-16 overflow-hidden rounded-lg">
            {isLoading && (
              <Skeleton className="absolute inset-0 w-full h-full rounded-lg" />
            )}
            <img
              src={imageUrl}
              alt={articleId.toString()}
              className={`w-full h-full object-cover ${
                isLoading ? "opacity-0" : "opacity-100"
              }`}
              onLoad={() => setIsLoading(false)}
            />
          </div>
        ) : (
          <div className="border flex items-center justify-center w-16 h-16">
            <ImageIcon className="w-6 h-6 text-sidebarGray-500" />
          </div>
        )}
      </TableCell>
    );
  };

  // Sort-Icon
  const getSortIcon = (field: string) => {
    if (sortBy === field) {
      return sortDirection === "ASC" ? (
        <ChevronUpIcon className="ml-2" />
      ) : (
        <ChevronDownIcon className="ml-2" />
      );
    } else {
      return <CaretSortIcon className="ml-2" />;
    }
  };

  // -------------------------------------------------------------
  // HIER Kommt das Drawer-Innere (inkl. REORDER & DELETE)
  // -------------------------------------------------------------

  // State für Bilder (lokal), Reorder-Flag
  const [localImages, setLocalImages] = useState<AEBImage[]>([]);
  const [isReordering, setIsReordering] = useState(false);

  // Wenn "product" wechselt (bzw. product.images), localImages aktualisieren
  useEffect(() => {
    if (product?.images) {
      setLocalImages(product.images);
    }
  }, [product?.images]);

  // Bild löschen
  const handleDelete = async (imageId: number) => {
    try {
      await deleteImage(imageId);
      if (product) {
        await fetchProductById(product.articleId);
      }
    } catch (error) {
      console.error("Fehler beim Löschen:", error);
    }
  };

  // Sortierung speichern
  const handleSaveOrder = async () => {
    // Hier die neue Reihenfolge an Backend schicken
    const newOrder = localImages.map((img, idx) => ({
      id: img.id,
      orderIndex: idx,
    }));
    try {
      await updateImageOrder(newOrder);
      if (product) {
        await fetchProductById(product.articleId);
      }
      setIsReordering(false);
    } catch (error) {
      console.error("Fehler beim Speichern der Bildreihenfolge:", error);
    }
  };

  return (
    <>
      {/* ---------- Such- und Filterbereich ---------- */}
      <div className="flex items-center justify-center gap-4 px-2 py-6 lg:px-0">
        <div className="w-full flex items-center justify-center gap-4 px-2 lg:px-0">
          <Input
            type="text"
            placeholder="Suche..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button className="w-max" variant={"default"} onClick={handleSearch}>
            Suche
          </Button>
        </div>
      </div>

      {/* ---------- Aktive Filter-Badges ---------- */}
      <div className="flex items-center justify-start gap-2">
        <Badge className="bg-transparent h-8 w-0 p-0 m-0 shadow-none"></Badge>
        {selectedSort && (
          <Badge className="flex items-center justify-between gap-1 w-max" variant={"default"}>
            {sortBy === "price" && <p>Preis</p>}
            {sortBy === "searchTerm" && <p>Name</p>}
            {sortDirection === "DESC" && <p>absteigend</p>}
            {sortDirection === "ASC" && <p>aufsteigend</p>}
            <button
              onClick={() => (
                setSelectedSort(undefined), setSortBy(""), setSortDirection("")
              )}
              className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
            >
              <Cross2Icon />
            </button>
          </Badge>
        )}
        {Object.entries(filters).map(([key, value]) => {
          if (!value) return null;
          let label = "";
          switch (key) {
            case "active":
              label = "Aktive Artikel";
              break;
            case "inactive":
              label = "Inaktive Artikel";
              break;
            case "showPrice":
              label = "Otto Aktiv";
              break;
            case "requestPrice":
              label = "Preis auf Anfrage";
              break;
            default:
              break;
          }
          return (
            <Badge
              key={key}
              className="flex items-center justify-between gap-1 w-max"
              variant={"default"}
            >
              {label}
              <button
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    [key]: false,
                  }))
                }
                className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
              >
                <Cross2Icon />
              </button>
            </Badge>
          );
        })}
        {selectedGroup && (
          <Badge className="flex items-center justify-between gap-2 w-max">
            {selectedGroup}
            <button
              onClick={() => setSelectedGroup(undefined)}
              className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
            >
              <Cross2Icon />
            </button>
          </Badge>
        )}
        {selectedBrand && (
          <Badge className="flex items-center justify-between gap-2 w-max">
            {selectedBrand}
            <button
              onClick={() => setSelectedBrand(undefined)}
              className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
            >
              <Cross2Icon />
            </button>
          </Badge>
        )}
        {searchTerm && (
          <Badge className="flex items-center justify-between gap-2 w-max">
            {searchTerm}
            <button
              onClick={() => setSearchTerm("")}
              className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
            >
              <Cross2Icon />
            </button>
          </Badge>
        )}
      </div>

      {/* ---------- Pagination ---------- */}
      {Array.isArray(productList) && productList.length > 0 ? (
        <div className="flex h-12 w-full items-center justify-end gap-4 -space-x-px text-sm">
          <button
            className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
            onClick={handlePageSelectFirst}
          >
            Start
          </button>
          <button
            className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
            onClick={handlePageSelectDec}
          >
            <ChevronLeftIcon />
            Zurück
          </button>
          {renderPageNumbers}
          <button
            className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
            onClick={handlePageSelectInc}
          >
            Weiter
            <ChevronRightIcon />
          </button>
          <button
            className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
            onClick={handlePageSelectLast}
          >
            Ende
          </button>
        </div>
      ) : null}

      {/* ---------- Tabelle ---------- */}
      <ScrollArea className="w-full rounded-md border">
        <ScrollBar orientation="horizontal" />
        <Table className="w-full">
          <TableHeader className="sticky">
            <TableRow>
              <TableHead className="cursor-pointer">
                <div className="flex items-center"></div>
              </TableHead>
              <TableHead
                onClick={() => handleSortChange("articleId")}
                className="cursor-pointer w-32 text-center"
              >
                <div className="flex items-center justify-center">
                  Art.-Nr.
                  {getSortIcon("articleId")}
                </div>
              </TableHead>
              <TableHead
                onClick={() => handleSortChange("barcode")}
                className="cursor-pointer w-32 text-left"
              >
                <div className="flex items-center justify-center">
                  EAN
                  {getSortIcon("articleId")}
                </div>
              </TableHead>
              <TableHead
                onClick={() => handleSortChange("referenceNumber")}
                className="cursor-pointer w-32 text-left"
              >
                <div className="flex items-center justify-center">
                  Referenznummer
                  {getSortIcon("articleId")}
                </div>
              </TableHead>
              <TableHead
                onClick={() => handleSortChange("brand")}
                className="cursor-pointer w-[100px]"
              >
                <div className="flex items-center">
                  Marke
                  {getSortIcon("articleId")}
                </div>
              </TableHead>
              <TableHead className="w-[180px]">Status</TableHead>
              <TableHead
                onClick={() => handleSortChange("searchTerm")}
                className="cursor-pointer flex items-center"
              >
                <div className="flex items-center">
                  Name
                  {getSortIcon("searchTerm")}
                </div>
              </TableHead>
              <TableHead>Kategorie</TableHead>
              <TableHead
                onClick={() => handleSortChange("price")}
                className="cursor-pointer w-32 text-left"
              >
                <div className="flex items-center justify-center">
                  Preis
                  {getSortIcon("price")}
                </div>
              </TableHead>
              <TableHead
                onClick={() => handleSortChange("available")}
                className="cursor-pointer w-[100px] text-right px-0"
              >
                <div className="flex items-center justify-center">
                  Bestand
                  {getSortIcon("available")}
                </div>
              </TableHead>
              <TableHead className="text-right w-[150px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.isArray(productList) && productList.length > 0 ? (
              productList.map((productMapped) => (
                <TableRow
                  key={productMapped.referenceNumber}
                  className="dark:text-white"
                >
                  <ProductImageCell
                    imageUrl={`/api/image/${productMapped.imageIds[0]}` || ""}
                    articleId={productMapped.articleId}
                  />
                  <TableCell className="font-medium text-center">
                    {productMapped.articleId}
                  </TableCell>
                  <TableCell className="font-medium text-center">
                    {productMapped.barcode}
                  </TableCell>
                  <TableCell className="font-medium text-center">
                    {productMapped.referenceNumber}
                  </TableCell>
                  <TableCell className="font-medium text-left">
                    {productMapped.brand}
                  </TableCell>
                  <TableCell className="font-medium flex items-center h-full justify-start gap-2">
                    {productMapped.onlineSaleAllowed ? (
                      <Badge className="bg-green-950 text-green-300">
                        Aktiv
                      </Badge>
                    ) : (
                      <Badge className="bg-gray-900 text-orange-300">
                        Inaktiv
                      </Badge>
                    )}
                    {productMapped.showPrice ? (
                      <Badge className="bg-green-950 text-green-300">
                        Otto Aktiv
                      </Badge>
                    ) : (
                      <Badge className="bg-orange-950 text-orange-300">
                        Otto Inaktiv
                      </Badge>
                    )}
                  </TableCell>
                  <TableCell className="font-medium w-[250px] text-left pr-4">
                    {productMapped.productName}
                  </TableCell>
                  <TableCell>{productMapped.productGroup}</TableCell>
                  <TableCell className="font-medium text-center">
                    {productMapped.price}€
                  </TableCell>
                  <TableCell className="text-center">
                    {productMapped.stockAmount}
                  </TableCell>
                  <TableCell className="h-full flex items-center justify-center gap-2">
                    {/* ---------- Drawer ---------- */}
                    <Drawer
                      onOpenChange={(open) => {
                        if (!open) {
                          setSelectedArticleId(null);
                        }
                      }}
                    >
                      <DrawerTrigger
                        asChild
                        className="w-12 h-12"
                        onClick={() => setSelectedArticleId(productMapped.articleId)}
                      >
                        <Button variant="outline">
                          <EyeOpenIcon className="w-5 h-5" />
                        </Button>
                      </DrawerTrigger>
                      {product && (
                        <DrawerContent className="h-[95vh] px-12">
                          <DrawerHeader className="flex flex-col">
                            <DrawerTitle className="text-3xl flex flex-col items-start justify-start p-0 m-0">
                              {product.productName}
                            </DrawerTitle>
                            <DrawerDescription>
                              {product.id}
                            </DrawerDescription>
                          </DrawerHeader>

                          {/* Hauptbereich im Drawer */}
                          <section className="flex items-start justify-start gap-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-sidebarGray-700 scrollbar-track-sidebarGray-800 custom-scrollbar overflow-x-hidden">
                            {/* Linke Spalte */}
                            <div className="w-2/3 flex flex-col items-start justify-start gap-4">
                              <Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
                                {/* Titelzeile: Produktbilder + Reorder-Button */}
                                <div className="w-full flex items-center justify-between">
                                  <span className="text-base text-gray-500 pl-1 pt-1">
                                    Produktbilder
                                  </span>
                                  {!isReordering ? (
                                    <Button
                                      variant="outline"
                                      onClick={() => setIsReordering(true)}
                                    >
                                      Reihenfolge ändern
                                    </Button>
                                  ) : (
                                    <Button variant="default" onClick={handleSaveOrder}>
                                      Änderungen speichern
                                    </Button>
                                  )}
                                </div>

                                {/* Upload-Dialog */}
                                <section className="flex my-4">
                                  <Dialog>
                                    <DialogTrigger asChild>
                                      <Button
                                        variant={"default"}
                                        className="flex items-center justify-center gap-3"
                                      >
                                        <PlusIcon size={"icon"} />
                                        Produktbilder hochladen
                                      </Button>
                                    </DialogTrigger>
                                    <DialogContent className="sm:max-w-[960px]">
                                      <DialogHeader>
                                        <DialogTitle>
                                          Produktbilder hochladen für "
                                          {product.articleId}"
                                        </DialogTitle>
                                        <DialogDescription>
                                          Laden Sie hier ihre neuen Produktbilder
                                          als Bild-Datei hoch.
                                        </DialogDescription>
                                      </DialogHeader>

                                      <div
                                        {...getRootProps({
                                          className:
                                            "border-2 border-dashed border-gray-300 rounded-md p-6 flex flex-col items-center justify-center cursor-pointer hover:border-gray-400 transition-colors",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                          <p className="text-center text-sm text-gray-500">
                                            Dateien hier loslassen ...
                                          </p>
                                        ) : (
                                          <p className="text-center text-sm text-gray-500">
                                            Ziehen Sie Bilder hierher oder
                                            klicken Sie, um welche auszuwählen.
                                          </p>
                                        )}
                                      </div>

                                      {selectedFiles.length > 0 && (
                                        <div className="mt-4">
                                          <h3 className="text-lg font-semibold mb-2">
                                            Ausgewählte Dateien:
                                          </h3>
                                          <ul className="space-y-1">
                                            {selectedFiles.map((file) => (
                                              <li
                                                key={file.name}
                                                className="flex items-center justify-between bg-gray-50 p-2 rounded text-black"
                                              >
                                                <span className="mr-2 text-sm text-zinc-900">
                                                  {file.name}
                                                </span>
                                                <Button
                                                  variant="destructive"
                                                  size="sm"
                                                  onClick={() => removeFile(file.name)}
                                                >
                                                  Entfernen
                                                </Button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                      <DialogFooter>
                                        <Button onClick={handleUpload}>
                                          Produktbilder jetzt hochladen
                                        </Button>
                                      </DialogFooter>
                                    </DialogContent>
                                  </Dialog>
                                </section>

                                {/* Bilder-Grid: NORMAL vs. REORDER */}
                                {localImages.length === 0 ? (
                                  <ImageIcon className="w-96 h-96 text-sidebarGray-500" />
                                ) : !isReordering ? (
                                  // Normaler Modus (Hover-Löschbutton)
                                  <div className="grid grid-cols-3 gap-2 mt-4">
                                    {localImages.map((image: AEBImage) => (
                                      <div
                                        key={image.id}
                                        className="relative border-sidebarGray-500 border-[1px] group"
                                      >
                                        <img
                                          src={image.imageUrl || ""}
                                          alt={product.productName}
                                          className="w-full h-full max-h-[250px] object-contain"
                                        />
                                        {/* Delete-Button auf Hover */}
                                        <button
                                          onClick={() => handleDelete(image.id)}
                                          className="absolute top-2 right-2 hidden group-hover:block bg-white text-red-600 rounded-full p-1 shadow"
                                        >
                                          <Cross2Icon />
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  // Reorder-Modus (Drag&Drop)
                                  <ReactSortable
                                    list={localImages}
                                    setList={setLocalImages}
                                    className="grid grid-cols-3 gap-2 mt-4"
                                  >
                                    {localImages.map((image: AEBImage) => (
                                      <div
                                        key={image.id}
                                        className="relative border-sidebarGray-500 border-[1px]"
                                      >
                                        <img
                                          src={image.imageUrl || ""}
                                          alt={product.productName}
                                          className="w-full h-full max-h-[250px] object-contain"
                                        />
                                        {/* Optional: Delete-Button auch im Reorder-Modus */}
                                        <button
                                          onClick={() => handleDelete(image.id)}
                                          className="absolute top-2 right-2 bg-white text-red-600 rounded-full p-1 shadow"
                                        >
                                          <Cross2Icon />
                                        </button>
                                      </div>
                                    ))}
                                  </ReactSortable>
                                )}
                              </Card>
                              <Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
                                {/* Titelzeile: Produktbilder + Reorder-Button */}
                                <div className="w-full flex items-center justify-between">
                                  <span className="text-base text-gray-500 pl-1 pt-1">
                                    Alte AEB Produktbilder
                                  </span>
                                </div>
                                <div className="grid grid-cols-3 gap-2 mt-4">
																	{product.imageIds.length === 0 ? (
																		<ImageIcon className="w-96 h-96 text-sidebarGray-500" />
																	) : (
																		product.imageIds.map(
																			(image: string, index: number) => {
																				if (index % 3 === 0) {
																					return (
																						<div
																							key={image}
																							className="relative border-sidebarGray-500 border-[1px]"
																						>
																							<img
																								src={`/api/image/${image}`}
																								alt={
																									product.productName
																								}
																								className="w-full h-full max-h-[250px] object-contain"
																							/>
																						</div>
																					);
																				}
																				return null;
																			}
																		)
																	)}
																</div>
                              </Card>
                            </div>

                            {/* Rechte Spalte */}
                            <div className="flex flex-col w-1/3 gap-4">
                              <Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    ID
                                  </span>
                                  <p className="text-lg">{product.articleId}</p>
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Produktname
                                  </span>
                                  {product.productName ? (
                                    <p className="text-lg">{product.productName}</p>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Beschreibung
                                  </span>
                                  {product.additionalInfo ? (
                                    <p className="text-lg">{product.additionalInfo}</p>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Details
                                  </span>
                                  <p className="text-lg">{product.additionalInfo}</p>
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Bestand
                                  </span>
                                  <p className="text-lg">{product.stockAmount}</p>
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Marke
                                  </span>
                                  <p className="text-lg">{product.brand}</p>
                                </div>
                                <div className="flex flex-col item-start justify-start">
                                  <span className="text-sm text-gray-500">
                                    Preis
                                  </span>
                                  <p className="text-lg">{product.price}</p>
                                </div>
                              </Card>
                              <Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
                                <span className="text-base text-gray-500 pt-1">
                                  Kontaktdaten des Herstellers
                                </span>
                                {product.manufacturerInfo ? (
                                  <p className="text-lg">
                                    {product.manufacturerInfo}
                                  </p>
                                ) : (
                                  <p>-</p>
                                )}
                              </Card>
                            </div>
                          </section>

                          <DrawerFooter className="flex flex-row items-end justify-end">
                            <DrawerClose className="flex items-center justify-center gap-6">
                              <Button variant="outline">Schließen</Button>
                            </DrawerClose>
                          </DrawerFooter>
                        </DrawerContent>
                      )}
                    </Drawer>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-12 text-xl">
                  Es wurden keine Produkte gefunden
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>

      {/* ---------- Pagination (unten) ---------- */}
      {Array.isArray(productList) && productList.length > 0 ? (
        <div className="flex h-12 w-full items-center justify-center gap-4 -space-x-px text-sm py-12">
          <button
            className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
            onClick={handlePageSelectFirst}
          >
            Start
          </button>
          <button
            className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
            onClick={handlePageSelectDec}
          >
            <ChevronLeftIcon />
            Zurück
          </button>
          {renderPageNumbers}
          <button
            className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
            onClick={handlePageSelectInc}
          >
            Weiter
            <ChevronRightIcon />
          </button>
          <button
            className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
            onClick={handlePageSelectLast}
          >
            Ende
          </button>
        </div>
      ) : null}
    </>
  );
};

export default ProductTableAEB;
