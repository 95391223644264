import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProductTableAEB from "../components/table/ProductTableAEB";
import ProductManufacturer from "../containers/admin/ProductManufacturer";
import { getUserRoleFromToken } from "../../utils/auth";

export interface AEBProduct {
	id: number;
	articleId: number;
	referenceNumber: string;
	saleText: string;
	brand: string;
	barcode: string;
	price: number;
	productLine: string;
	productType: string;
	productGroup: string;
	articleGroup: string;
	productFamily: string;
	diameter: string;
	waterResistance: string;
	clockFaceType: string;
	clockFaceColor: string;
	finish: string;
	bodyMaterialBezel: string;
	clockworkType: string;
	watchGlass: string;
	wristband: string;
	wristbandColor: string;
	wristbandMaterial: string;
	wristbandClosure: string;
	wristbandClosureMaterial: string;
	additionalInfo: string;
	height: number;
	clientId: number;
	createdAt: Date;
	productName: string;
	stockAmount: number;
	showPrice: boolean;
	onlineSaleAllowed: boolean;
	mainImage: Text;
	manufacturerInfo: string;
	imageIds: string[];
  images: AEBImage[]
}

export interface AEBImage {
  id: number;
  imageId: number;
  imageData: string;
  extension: string;
  articleId: number;
  imageUrl?: string;
  role: string;
  orderIndex: number | null;
}

export interface Brand {
	id: number;
	name: string;
	manufacturerInfo: string;
	products: AEBProduct[];
}

interface ProductContextType {
	productList: AEBProduct[];
	product: AEBProduct | undefined;
	productGroups: string[];
	fetchProducts: (
		page?: number,
		sortBy?: string,
		sortDirection?: string,
		productGroup?: string,
		sortStatus?: string,
		brand?: string
	) => Promise<void>;
	editProduct: (manufacturerInfo: string, brand: string) => Promise<void>;
	updateProductStatus: (articleId: number, active: boolean) => void;
	updatePriceStatus: (articleId: number, active: boolean) => void;
	searchProducts: (term: string, page?: number, sortBy?: string, sortDirection?: string) => void;
	fetchProductGroups: () => void;
	fetchBrands: () => void;
	fetchProductById: (articleId: number) => void;
	getAllBrands: () => void;
	totalPages: number;
	totalProducts: number;
	currentPage: number;
	brands: string[];
	allBrands: Brand[];
	setCurrentPage: (page: number) => void;
  uploadImages: (files: File[], articleId: number, customer?: string) => Promise<AEBImage[] | void>;
  deleteImage: (imageId: number) => Promise<void>;
  updateImageOrder: (orderData: { id: number; orderIndex: number }[]) => Promise<void>;
}

export const ProductContextAEB = createContext<ProductContextType>({} as ProductContextType);

export const ProductProviderAEB: React.FC<PropsWithChildren<object>> = ({ children }) => {
	const [productList, setProductList] = useState<AEBProduct[]>([]);
	const [product, setProduct] = useState<AEBProduct>();
	const [brands, setBrands] = useState<[]>([]);
	const [allBrands, setAllBrands] = useState<Brand[]>([]);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalProducts, setTotalProducts] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [productGroups, setProductGroups] = useState<string[]>([]);
	const navigate = useNavigate();
	const userRole = getUserRoleFromToken();

	async function fetchProducts(
		page = 1,
		sortBy = "articleId",
		sortDirection = "ASC",
		productGroup: string | null = null,
		sortStatus: string | undefined = undefined,
		brand: string | null = null
	) {
		if (userRole !== "bucher") {
			return;
		}

		try {
			const params: any = {
				page: page.toString(),
				sortBy,
				sortDirection,
				...(productGroup && { productGroup }),
				...(brand && { brand }),
			};

			if (sortStatus) {
				sortStatus.split("&").forEach((filter) => {
					const [field, value] = filter.split("=");
					if (field && value) {
						params[field] = value;
					}
				});
			}

			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/aeb/products`, {
				params,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				setProductList(response.data.products);
				setTotalPages(response.data.totalPages);
				setTotalProducts(response.data.total);
			} else if (response.status === 401) {
				navigate("/login");
			} else {
				console.error("Unexpected status code:", response.status);
			}
		} catch (error) {
			console.error("Beim Laden der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	const fetchProductById = async (articleId: number) => {
		if (userRole !== "bucher") {
			return;
		}

		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/aeb/product/${articleId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setProduct(response.data);
			} else {
				console.error("Error updating product status:", response.status);
			}
		} catch (error) {
			console.error("Error updating product status:", error);
		}
	};

	const updateProductStatus = async (articleId: number, active: boolean) => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.put(
				`/api/aeb/product/${articleId}/status`,
				{ active },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status === 200) {
				setProductList((prevList) =>
					prevList.map((product) => (product.articleId === articleId ? { ...product, active } : product))
				);
			} else {
				console.error("Error updating product status:", response.status);
			}
		} catch (error) {
			console.error("Error updating product status:", error);
		}
	};

	const updatePriceStatus = async (articleId: number, showPrice: boolean) => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.put(
				`/api/aeb/product/${articleId}/show-price`,
				{ showPrice },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status === 200) {
				setProductList((prevList) =>
					prevList.map((product) => (product.articleId === articleId ? { ...product, showPrice } : product))
				);
			} else {
				console.error("Error updating product status:", response.status);
			}
		} catch (error) {
			console.error("Error updating product status:", error);
		}
	};

	async function searchProducts(term = "", page = 1, sortBy = "articleId", sortDirection = "ASC") {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const params = {
				term,
				page: page.toString(),
				sortBy,
				sortDirection,
			};
			const response = await axios.get(`/api/aeb/search`, {
				params,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data.products;
				const totalTemp = response.data.total;
				const totalPagesTemp = response.data.totalPages;
				const pageTemp = response.data.page;
				setProductList(data);
				setTotalPages(totalPagesTemp);
				setTotalProducts(totalTemp);
				setCurrentPage(pageTemp);
			}
		} catch (error) {
			console.error("Beim Suchen der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	const getAllBrands = async () => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/aeb/get-brands`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setAllBrands(response.data);
			} else {
				console.error("Error editing product:", response.status);
			}
		} catch (error) {
			console.error("Error editing product:", error);
		}
	};

	const editProduct = async (manufacturerInfoUserQuery: string, brand: string) => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.put(
				`/api/aeb/brand/${brand}`,
				{
					manufacturerInfo: manufacturerInfoUserQuery,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status === 200) {
				fetchProducts();
			} else {
				console.error("Error editing product:", response.status);
			}
		} catch (error) {
			console.error("Error editing product:", error);
		}
	};

	const fetchProductGroups = async () => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/aeb/product-groups`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				const filteredData = response.data.filter((item: string) => item !== "");
				setProductGroups(filteredData);
			} else {
				console.error("Error fetching product groups:", response.status);
			}
		} catch (error) {
			console.error("Error fetching product groups:", error);
		}
	};

	const fetchBrands = async () => {
		if (userRole !== "bucher") {
			return;
		}
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/aeb/brands`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setBrands(response.data);
			} else {
				console.error("Error fetching product groups:", response.status);
			}
		} catch (error) {
			console.error("Error fetching product groups:", error);
		}
	};

  const uploadImages = async (
    files: File[],
    articleId: number,
    customer: string = "default"
  ): Promise<AEBImage[] | void> => {
    if (userRole !== "bucher" && userRole !== "admin") {
      return;
    }

    try {
      const token = localStorage.getItem("authToken");

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("imageFiles", file);
      });
      formData.append("articleId", articleId.toString());
      formData.append("customer", customer);

      const response = await axios.post(`/api/aeb/upload-images`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const uploadedImages: AEBImage[] = response.data.images;
        await fetchProductById(articleId);
        return uploadedImages;
      }
    } catch (error) {
      console.error("Fehler beim Hochladen der Bilder:", error);
    }
  };

  // 2. Bild löschen
  const deleteImage = async (imageId: number) => {
    if (userRole !== "bucher" && userRole !== "admin") {
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.delete(`/api/aeb/delete-image/${imageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        console.log("Bild erfolgreich gelöscht.");

        // Optional: State aktualisieren (falls du eine Liste von Bildern im State hältst)
        // Beispiel: Bilder aus dem 'product' herausfiltern
        // if (product && product.images) {
        //   const updatedImages = product.images.filter(img => img.id !== imageId);
        //   setProduct({ ...product, images: updatedImages });
        // }
      }
    } catch (error) {
      console.error("Fehler beim Löschen des Bildes:", error);
    }
  };

  const updateImageOrder = async (orderData: { id: number; orderIndex: number }[]) => {
    if (userRole !== "bucher" && userRole !== "admin") {
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.put(
        `/api/aeb/update-image-order`,
        { order: orderData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Bildreihenfolge erfolgreich aktualisiert.");
        // Optional: Nach erfolgreichem Update evtl. Produkt neu laden oder
        // deinen lokalen Bild-Array in passender Reihenfolge aktualisieren
      }
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Bildreihenfolge:", error);
    }
  };

	useEffect(() => {
		fetchProducts();
		fetchProductGroups();
		fetchBrands();
		getAllBrands();
	}, [ProductTableAEB, ProductManufacturer]);

	return (
		<ProductContextAEB.Provider
			value={{
				fetchProducts,
				product,
				allBrands,
				fetchBrands,
				editProduct,
				productList,
				getAllBrands,
				updateProductStatus,
				productGroups,
				updatePriceStatus,
				fetchProductGroups,
				searchProducts,
				totalPages,
				totalProducts,
				currentPage,
				brands,
				setCurrentPage,
				fetchProductById,
        uploadImages,
        deleteImage,
        updateImageOrder
			}}
		>
			{children}
		</ProductContextAEB.Provider>
	);
};

export const useProductsAEB = () => useContext(ProductContextAEB);
