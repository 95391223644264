import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const isAuthenticated = (): boolean => {
    return !!localStorage.getItem('authToken');
};

export function parseJwt(token: string) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export function isTokenExpired(token: string): boolean {
    const decoded = parseJwt(token);
    return decoded ? (Date.now() / 1000) > decoded.exp : true;
}

export function getUserRoleFromToken(): string | undefined {
    const token = localStorage.getItem('authToken');
    return token ? parseJwt(token)?.role : undefined;
}

interface ProtectedRouteProps {
    children: ReactNode;
    requiredRole?: string;
}

export const ProtectedRoute = ({ children, requiredRole }: ProtectedRouteProps) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');
    const userRole = getUserRoleFromToken();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (!token || !userRole || isTokenExpired(token)) {
            localStorage.removeItem('authToken');
            navigate('/login');
        } else if (requiredRole && userRole !== requiredRole) {
            navigate('/');
        } else {
            setIsAuthorized(true);
        }
    }, [navigate, token, userRole, requiredRole]);

    if (!isAuthorized) {
        return null;
    }

    return <>{children}</>;
};
